<template></template>
<script>
import { mapActions } from "vuex";
export default {
	props: ["token"],
	data() {
		return {}
	},
	methods: {
		...mapActions("auth", ["_verify", "_sendVerifyEmail"])
	},
	async created() {
		try {
			const { message } = await this._verify(this.token);
			this.$message.success(message);
			this.$router.push("/login");
		} catch (error) {
			this.$prompt(error, {
				type: "warning",
				inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
				inputErrorMessage: "請輸入正確信箱"
			}).then(async ({ value }) => {
				await this._sendVerifyEmail({ email: value });
				this.$alert(`請至 ${value} 收信，並於５分鐘內完成驗證。`, { type: "success" });
			})
		}
	}
}
</script>